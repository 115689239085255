$_min-viewport-size: 300; // in px
$_max-viewport-size: 1600; // in px

// mixins
@mixin size($size) {
  width: $size;
  height: $size;
}

@mixin circle($diameter) {
  @include size($diameter);
  border-radius: 50%;
}

@mixin fluid(
  $property,
  $min-size,
  $max-size,
  $units,
  $min-viewport-size: $_min-viewport-size,
  $max-viewport-size: $_max-viewport-size
) {
  #{$property}: calc(
    #{$min-size}#{$units} + (#{$max-size} - #{$min-size}) * ((
            100vw - #{$min-viewport-size}#{$units}
          ) / (#{$max-viewport-size} - #{$min-viewport-size}))
  );
}

@mixin size-fluid($min-size, $max-size, $units) {
  @include fluid(width, $min-size, $max-size, $units);
  @include fluid(height, $min-size, $max-size, $units);
}

@mixin circle-fluid($min-size, $max-size, $units) {
  @include size-fluid($min-size, $max-size, $units);
  border-radius: 50%;
}

// base properties
%pseudo {
  position: absolute;
  content: '';
}

%hoverable {
  &:hover {
    cursor: pointer;
  }
}

%center-fixed {
  transform:  translate(-50%, -50%);
  position: fixed;
  top: 50%;
  left: 50%;
}

%non-selectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
