@import '../../../../_shared';

.theme {
  &__bg-primary {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100); // exact vh for mobile
    width: 100%;
    position: fixed;
    overflow: hidden;

    div {
      width: 100%;
      height: 100%;
    }
  }

  &__bg-secondary {
    > div {
      position: absolute;
    }
  }

  &__nav {
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    // https://github.com/Schepp/CSS-Filters-Polyfill
    filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.7));

    > svg {
      position: relative;
      font-size: 40px;
      display: block;
      color: #fff;
    }

    &:not(&--hidden) {
      @extend %hoverable;
    }

    &--hidden {
      opacity: 0;
    }

    &--up {
      top: 0;
      margin-top: 1rem;

      > svg {
        animation: bounce-up 2s infinite;
      }
    }

    &--down {
      bottom: 0;
      margin-bottom: 1rem;

      > svg {
        animation: bounce-down 2s infinite;
      }
    }
  }

  &__credits {
    @extend %non-selectable;
    position: absolute;
    color: #fff;
    bottom: 1em;
    right: 1em;

    a {
      color: #575757;
    }
  }
}

.themify {
  &--active-color-hover,
  &--inactive-color-hover,
  &--active-color-invert-hover,
  &--inactive-color-invert-hover {
    @extend %hoverable;
    transition: color 0.25s;
  }

  &--active-background-hover,
  &--inactive-background-hover,
  &--active-background-invert-hover,
  &--inactive-background-invert-hover {
    @extend %hoverable;
    transition: background-color 0.25s;
  }
}

@keyframes bounce-down {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
    opacity: 1;
  }
  40% {
    transform: translateY(-30px);
    opacity: 0.5;
  }
  60% {
    transform: translateY(-15px);
    opacity: 0.75;
  }
}

@keyframes bounce-up {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
    opacity: 1;
  }
  40% {
    transform: translateY(30px);
    opacity: 0.5;
  }
  60% {
    transform: translateY(15px);
    opacity: 0.75;
  }
}
