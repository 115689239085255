@use 'sass:map';
@use '../../constants';
@import '../../_shared';

.section-landing {
  $self: &;

  &__headshot {
    @extend %non-selectable;
    @include circle-fluid(18, 22, vw);
    background-color: #262626;
    border: 0.35rem solid #262626;
    z-index: 2;
    position: relative;
    display: inline-block;
    margin: auto;
    vertical-align: middle;

    @media screen and (max-width: 1200px) {
      @include circle-fluid(22, 27, vw);
    }

    @media screen and (max-width: 935px) {
      @include circle-fluid(27, 30, vw);
    }

    @media screen and (max-width: 500px) {
      @include circle-fluid(30, 35, vw);
    }
  }

  &__info {
    vertical-align: middle;
    display: inline-block;
    padding: 0 0 0 1.5rem;
  }

  &__name {
    line-height: 1.1;
    margin: 0.5rem 0 0.5rem 0;

    $font-size-default: map.get(constants.$font-size, 'h1');

    &--xl {
      font-size: $font-size-default;
    }

    &--lg {
      font-size: $font-size-default - 0.2em;
    }

    &--md {
      font-size: $font-size-default - 0.4em;
    }

    &--sm {
      font-size: $font-size-default - 0.6em;
    }

    &--xs {
      font-size: $font-size-default - 0.8em;
    }
  }

  &__title {
    line-height: 1.1;
    margin: 0.5rem 0 0.5rem 0;

    $font-size-default: map.get(constants.$font-size, 'h2');

    &--xl {
      font-size: $font-size-default;
    }

    &--lg {
      font-size: $font-size-default - 0.07em;
    }

    &--md {
      font-size: $font-size-default - 0.14em;
    }

    &--sm {
      font-size: $font-size-default - 0.21em;
    }

    &--xs {
      font-size: $font-size-default - 0.28em;
    }
  }

  &__status {
    margin: 1rem 0 0.5rem 0;
  }

  &__status-alt {
    margin: 0.5rem 0 0.5rem 0;
  }

  &__status,
  &__status-alt {
    $font-size-default: map.get(constants.$font-size, 'p');

    &--xl {
      font-size: $font-size-default;
    }

    &--lg {
      font-size: $font-size-default - 0.05em;
    }

    &--md {
      font-size: $font-size-default - 0.1em;
    }

    &--sm {
      font-size: $font-size-default - 0.15em;
    }

    &--xs {
      font-size: $font-size-default - 0.2em;
    }
  }

  &__past {
    margin: 0.3rem 0 0 0;

    $font-size-default: map.get(constants.$font-size, 'h4');

    &--xl {
      font-size: $font-size-default;
    }

    &--lg {
      font-size: $font-size-default - 0.025em;
    }

    &--md {
      font-size: $font-size-default - 0.05em;
    }

    &--sm {
      font-size: $font-size-default - 0.075em;
    }

    &--xs {
      font-size: $font-size-default - 0.1em;
    }
  }

  // Extend left side of ribbon (only if type='right') so that it extends underneath the headshot, without affecting width of all info
  // NOTE: This is neccessary because condensed view depends on width of all info, can cause jankiness if we use padding instead as it
  //       would repeatedly jump back and forth between the condensed and non-condensed layout
  .ribbon--right {
    margin-left: -1rem;

    > div:before {
      content: '';
      display: block;
      position: absolute;
      height: 100%;
      width: 3rem;
      top: 0;
      left: -3rem;
      background-color: inherit;
    }
  }

  &--condensed {
    #{ $self }__headshot {
      display: block;
    }

    #{ $self }__info {
      text-align: center;
      margin: 0 0 0 -1.5rem;
    }
  }

  // TODO(alipianu): hide headshot if screen height too small (ie. portrait on mobile)
}
