@import '../../_shared';

// local variables
$shape-size: 130px;
$perspective: 300px;

div.scene {
  @extend %center-fixed;

  > div.shapes {
    @include size($shape-size);
    perspective: $perspective;
    margin: auto;
  }
}
