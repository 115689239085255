@import '../../_shared';

div.shape {
  @include size(100%);
  transform-style: preserve-3d;
  position: absolute;

  &.animated {
    // note: spin-shapes keyframe dynamically generated in polyhedrons.js
    animation: spin-shapes 5s 1s alternate 2 both;
  }

  &:not(.animated) {
    display: none;
  }
}
