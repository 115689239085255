@import '../../_shared';

@mixin fade-text($name, $startColor: null, $endColor: null) {
  @keyframes #{$name} {
    from {
      @if $startColor {
        color: $startColor;
      }
    }
    to {
      @if $endColor {
        color: $endColor;
      }
    }
  }
}

// local variables
$title-margin-top: 2em;

div.loader {
  text-align: center;
  color: transparent;
  background-color: #262626;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .loader-text {
    @extend %non-selectable;
    &:not(.hidden) {
      @include fade-text(fade-in, transparent, #fff);
      animation: fade-in 2s ease-in forwards;
    }
  }
  h2 {
    font-size: 40px;
    font-family: 'Righteous', cursive;
    margin: $title-margin-top 0 0 0;
  }
}
