@import '../../_shared';

.ribbon {
  $self: &;

  > div {
    background-color: #262626;
    position: relative;
    color: #fff;
    display: inline-block;
  }

  &--double {
    > div {
      padding: 0.35em 1.5em 0.5em 1.5em;
    }

    &#{ $self }--titled {
      > div {
        padding: 0 0.5rem 0 0;
      }

      #{ $self }__title {
        padding: 0 0.25rem 0 1rem;
      }

      #{ $self }__text {
        margin: 0 0.5rem 0 0.5rem;
      }
    }
  }

  &--right {
    > div {
      padding: 0.35em 1.5em 0.5em 0.8em;
    }

    &#{ $self }--titled {
      > div {
        padding: 0 0.8em 0 0;
      }

      #{ $self }__title {
        padding: 0 0.25em 0 0.25em;
        margin-left: 2px;
      }

      #{ $self }__text {
        margin: 0 0 0 0.4em;
      }
    }

    #{ $self }__left {
      display: none;
    }
  }

  &--left {
    > div {
      padding: 0.35em 0.8em 0.5em 1.5em;
    }

    &#{ $self }--titled {
      > div {
        padding: 0 0.25em 0 0;
      }

      #{ $self }__title {
        padding: 0 0.25em 0 0.8em;
      }

      #{ $self }__text {
        margin: 0 0 0 0.4em;
      }
    }

    #{ $self }__right {
      display: none;
    }
  }

  &__right {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjxzdmcKICAgeG1sbnM6ZGM9Imh0dHA6Ly9wdXJsLm9yZy9kYy9lbGVtZW50cy8xLjEvIgogICB4bWxuczpjYz0iaHR0cDovL2NyZWF0aXZlY29tbW9ucy5vcmcvbnMjIgogICB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiCiAgIHhtbG5zOnN2Zz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciCiAgIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKICAgaWQ9InN2ZzIyIgogICB2ZXJzaW9uPSIxLjEiCiAgIGZpbGw9IiNmZmYiCiAgIHdpZHRoPSIxMCIKICAgdmlld0JveD0iMCAwIDEwIDQwIgogICBoZWlnaHQ9IjQwIj4KICA8bWV0YWRhdGEKICAgICBpZD0ibWV0YWRhdGEyOCI+CiAgICA8cmRmOlJERj4KICAgICAgPGNjOldvcmsKICAgICAgICAgcmRmOmFib3V0PSIiPgogICAgICAgIDxkYzpmb3JtYXQ+aW1hZ2Uvc3ZnK3htbDwvZGM6Zm9ybWF0PgogICAgICAgIDxkYzp0eXBlCiAgICAgICAgICAgcmRmOnJlc291cmNlPSJodHRwOi8vcHVybC5vcmcvZGMvZGNtaXR5cGUvU3RpbGxJbWFnZSIgLz4KICAgICAgICA8ZGM6dGl0bGU+PC9kYzp0aXRsZT4KICAgICAgPC9jYzpXb3JrPgogICAgPC9yZGY6UkRGPgogIDwvbWV0YWRhdGE+CiAgPGRlZnMKICAgICBpZD0iZGVmczI2IiAvPgogIDxwYXRoCiAgICAgaWQ9InBhdGgyMCIKICAgICBmaWxsLXJ1bGU9ImV2ZW5vZGQiCiAgICAgZD0iTSAwLDIwIDEwLDQwIFYgMCBaIiAvPgo8L3N2Zz4K);
    background-position: right;
    transform: translate(1px);
    z-index: 1;
  }

  &__left {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-image: url(data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjQwIiB2aWV3Qm94PSIwIDAgMTAgNDAiIHdpZHRoPSIxMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBmaWxsPSIjZmZmIj48cGF0aCBkPSJtNSAxNSAyMCAxMGgtNDB6IiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHRyYW5zZm9ybT0ibWF0cml4KDAgLTEgLTEgMCAyNSAyNSkiLz48L3N2Zz4=);
    transform: translate(-1px);
  }

  &__title {
    background-color: #ffffff;
    color: #262626;
    float: left;

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 2px;
      left: -1px;
      top: 0;
      background-color: #262626;
    }

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 2px;
      left: -1px;
      bottom: 0;
      background-color: #262626;
    }

    position: relative;
    z-index: 2;
  }

  &__text {
    float: left;
    background-color: inherit;
    position: relative;
    z-index: 2;
  }
}
